<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Adı Soyadı"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Adı Soyadı"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                placeholder="Adı Soyadı"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Ünvan"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Ünvan"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="itemData.title"
                placeholder="Ünvan"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Telefon"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Telefon"
              rules="required"
            >
              <b-form-input
                id="phone"
                v-model="itemData.phone"
                placeholder="Telefon"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="text-muted font-small-2">
              *Telefon numarasını ülke kodu ile beraber (Örnek: 905323501020) formatta olarak yazınız.
            </div>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Başlangıç Saati"
            label-for="stime"
          >
            <b-form-timepicker
              v-model="itemData.stime"
              v-bind="labels[locale] || {}"
              :locale="locale"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Bitiş Saati"
            label-for="etime"
          >
            <b-form-timepicker
              v-model="itemData.etime"
              v-bind="labels[locale] || {}"
              :locale="locale"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormFile, BFormGroup, BFormInput, BImg, BRow, BButton, BFormTimepicker,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ItemForm',
  components: {
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BButton,
    BFormTimepicker,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr-TR',
      labels: {
        'tr-TR': {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seç',
          labelNoTimeSelected: 'Saat Seçilmedi',
          labelCloseButton: 'Kapat',
        },
      },
      required,
      imageUrl: this.$store.state.app.baseURL,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['whatsapp/dataItem']
    },
  },
}
</script>
